<template>
  <v-row class="mt-1">
    <v-col class="pa-6">
      <v-col class="px-0">
        <v-col class="shades transparent">
          <v-row>
            <v-col cols="12" md="12">
              <h2 class="font-weight-bold headline hidden-sm-and-down">
                등급별 혜택을 설정해주세요.
              </h2>
            </v-col>
            <v-col class="mt-2" cols="12" v-if="form.naver">
              <div class="line my-6"></div>
              <v-col cols="12" md="12" v-if="adsData.type === 'S'">
                <v-col cols="12" md="4">
                  <div class="body-1 font-weight-bold mb-2">
                    할인 적용 최대금액<span class="caption ml-1 rouge--text"
                      >*필수</span
                    >
                  </div>
                  <v-text-field
                    v-model.number="naverPrice"
                    name="naverPrice"
                    type="number"
                    suffix="원"
                    class="text-right"
                    :rules="[
                      errors.first('naverPrice') || !errors.has('naverPrice'),
                    ]"
                    @input="checkDone"
                    :disabled="form.naver.available === 0"
                    single-line
                    outlined
                    solo
                    flat
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col class="mb-2 ml-2" cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="8" class="estimate">
                      <bulbIcon class="light_bulb" />
                      <div>
                        식당 등 요식업 - 2인을 기준으로 넉넉하게 이용할 수 있는
                        금액을 입력해주세요.<br />미용실 등 그 외 매장 - 1인을
                        기준으로 넉넉하게 이용할 수 있는 금액을 입력해주세요.
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="mt-6">
                  <div class="body-1 font-weight-bold mb-2">
                    등급별 할인율<span class="caption mx-1 rouge--text"
                      >*필수</span
                    >
                    <a
                      style="text-decoration: underline"
                      @click="calculate('naver')"
                      >금액 계산해보기 ></a
                    >
                  </div>
                </v-col>
                <v-col class="ml-2" cols="12" md="12">
                  <v-row
                    :style="
                      $vuetify.breakpoint.mdAndUp ? '' : 'flex-direction: column;'
                    "
                  >
                    <v-col md="6" cols="7">
                      <v-row>
                        <!-- <blackIcon class="level_icon" /> -->
                        <img
                          class="level_icon"
                          src="https://s3.ap-northeast-2.amazonaws.com/supermembers.app/level/new_black.png"
                        />
                        <v-col md="6" class="ml-4">
                          <div class="mb-2">블랙 등급</div>
                          <v-text-field
                            v-model.number="form.naver.black"
                            name="naverBlack"
                            type="number"
                            mask="###"
                            v-validate="'max_value:100'"
                            suffix="%"
                            class="text-right"
                            :rules="[
                              errors.first('naverBlack') ||
                                !errors.has('naverBlack'),
                            ]"
                            @input="checkDone"
                            @click="
                              form.naver.black = 0;
                              $forceUpdate();
                            "
                            :disabled="form.naver.available === 0"
                            single-line
                            outlined
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="6" cols="7">
                      <v-row>
                        <img
                          class="level_icon"
                          src="https://s3.ap-northeast-2.amazonaws.com/supermembers.app/level/new_red.png"
                        />
                        <v-col md="6" class="ml-4">
                          <div class="mb-2">레드 등급</div>
                          <v-text-field
                            v-model.number="form.naver.red"
                            name="naverRed"
                            type="number"
                            mask="###"
                            v-validate="'max_value:100'"
                            suffix="%"
                            class="text-right"
                            :rules="[
                              errors.first('naverRed') || !errors.has('naverRed'),
                            ]"
                            @input="checkDone"
                            @click="
                              form.naver.red = 0;
                              $forceUpdate();
                            "
                            :disabled="form.naver.available === 0"
                            single-line
                            outlined
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="6" cols="7">
                      <v-row>
                        <img
                          class="level_icon"
                          src="https://s3.ap-northeast-2.amazonaws.com/supermembers.app/level/new_yellow.png"
                        />
                        <v-col md="6" class="ml-4">
                          <div class="mb-2">옐로우 등급</div>
                          <v-text-field
                            v-model.number="form.naver.yellow"
                            name="naverYellow"
                            type="number"
                            mask="###"
                            v-validate="'max_value:100'"
                            suffix="%"
                            class="text-right"
                            :rules="[
                              errors.first('naverYellow') ||
                                !errors.has('naverYellow'),
                            ]"
                            @input="checkDone"
                            @click="
                              form.naver.yellow = 0;
                              $forceUpdate();
                            "
                            :disabled="form.naver.available === 0"
                            single-line
                            outlined
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="6" cols="7">
                      <v-row>
                        <img
                          class="level_icon"
                          src="https://s3.ap-northeast-2.amazonaws.com/supermembers.app/level/new_white.png"
                        />
                        <v-col md="6" class="ml-4">
                          <!-- <div class="mb-2">화이트 등급</div>
                           -->
                          <v-checkbox
                            v-model="form.naver.enableWhite"
                            @change="checkDone"
                            class="v-checkbox-white__container"
                            hide-details
                            :disabled="!adsData.receiptReviewRequired"
                          >
                            <template v-slot:label>
                              <span
                                :style="{
                                  fontSize: '14px',
                                  color: !adsData.receiptReviewRequired
                                    ? '#C2C2C2'
                                    : '#000000DE;',
                                }"
                                >화이트 등급</span
                              >
                            </template>
                          </v-checkbox>
                          <v-text-field
                            v-model.number="form.naver.white"
                            name="naverWhite"
                            type="number"
                            mask="###"
                            v-validate="'max_value:100'"
                            suffix="%"
                            class="text-right"
                            :rules="[
                              errors.first('naverWhite') ||
                                !errors.has('naverWhite'),
                            ]"
                            @input="checkDone"
                            @click="
                              form.naver.white = 0;
                              $forceUpdate();
                            "
                            :class="{
                              'white-text-field':
                                !adsData.receiptReviewRequired ||
                                !form.naver.enableWhite,
                            }"
                            :disabled="
                              form.naver.available === 0 ||
                              !adsData.receiptReviewRequired ||
                              !form.naver.enableWhite
                            "
                            single-line
                            outlined
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div
                    style="
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 16px;
                      color: #bd1328;
                    "
                  >
                    *화이트 등급은 네이버 영수증 리뷰만 지원합니다.
                    <span v-if="$vuetify.breakpoint.mdAndUp">
                      <span style="font-weight: bold"> 화이트 등급의 할인율 </span
                      >을 설정하시려면<br />
                      <span style="font-weight: bold">광고정보 탭 </span>에서
                      <span style="font-weight: bold">
                        영수증 리뷰 여부를 “예"</span
                      >로 설정해주세요.
                    </span>
                  </div>
                  <div
                    v-if="form.naver.available === 0"
                    class="body-1 mb-2 secondary--text"
                  >
                    광고가 게시중인 상태이므로 직접 수정이 불가합니다.<br />변경을
                    원하시는 경우 우측 하단 채팅버튼을 통해 수정을 요청해주세요.
                  </div>
                </v-col>
              </v-col>
              <v-col cols="12" md="12" v-else-if="adsData.type === 'A'">
                <v-col cols="12" md="4">
                  <div class="label mb-2">
                    할인 적용 최대금액<span class="caption ml-1 rouge--text"
                  >*필수</span
                  >
                  </div>
                  <v-text-field
                      v-model.number="naverPrice"
                      name="naverPrice"
                      type="number"
                      suffix="원"
                      class="text-right"
                      :rules="[
                      errors.first('naverPrice') || !errors.has('naverPrice'),
                    ]"
                      @input="checkDone"
                      :disabled="form.naver.available === 0"
                      single-line
                      outlined
                      solo
                      flat
                      hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="11" md="11">
                  <div class="label my-2">
                    필수미션을 입력해주세요.<a href="https://www.notion.so/mayacrew/7c67b704a9e64004aaa8a5ead1c0ef3a"
                                    class="caption text-decoration-underline"
                                    target="_blank">필수미션 작성 예시 보기></a>
                  </div>
                  <v-row v-for="(m, index) in form.naver.mission || []" :key="index">
                    <v-textarea v-model="form.naver.mission[index]"
                                name="mission" type="text" class="mt-2" outlined solo flat hide-details></v-textarea>
                    <v-btn color="rouge" @click="removeMission(index)" icon text class="plus_btn">
                      <v-icon style="color: #757575">mdi-trash-can-outline</v-icon></v-btn>
                  </v-row>
                  <v-row>
                    <v-textarea v-model="mission" name="mission" type="text" class="mt-2"
                                @keyup.enter="addMission" outlined solo flat hide-details></v-textarea>
                    <v-btn color="rouge" @click="addMission" tile class="plus_btn">추가</v-btn>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="label my-2">
                    할인율<span class="caption ml-1 rouge--text">*필수</span>
                  </div>
                  <v-col cols="2">
                    <v-text-field type="text" class="mt-2" value="100%"
                                  single-line outlined solo flat disabled hide-details></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="label my-2">
                    원고료<span class="caption ml-1 rouge--text">*필수</span>
                  </div>
                  <v-col cols="4">
                    <v-text-field type="number" class="mt-2 text-right" v-model.number="form.diamondPoint" @input="checkDone"
                                  suffix="원" single-line outlined solo flat hide-details readonly disabled></v-text-field>
                  </v-col>
<!--                  <v-col>-->
<!--                    <span class="caption primary&#45;&#45;text">적정 원고료는 20,000원~50,000원입니다.</span>-->
<!--                  </v-col>-->
                </v-col>
                <v-col cols="12" md="12">
                  <v-col cols="12">
                    <div class="label mb-2">
                      모집 희망인원<span class="caption ml-1 rouge--text">*필수</span>
                    </div>
<!--                    <v-checkbox v-model="naver_limit" label="제한 없음" color="rouge" class="check ma-0" height="20px"></v-checkbox>-->
                    <v-row v-if="!naver_limit">
                      <v-col class="pa-1" cols="8" md="6">
                        <v-slider v-model="naver_number" @change="checkDone"
                                  always-dirty :min="adsData.type === 'A' ? 5 : 10" :max="adsData.type === 'A' ? 50 : 100" :tick-labels="tickLabels" tick-size="0"
                                  :step="adsData.type === 'A' ? 5 : 10" show-ticks="always" hide-details
                        ></v-slider>
                      </v-col>
                      <v-col cols="3" class="pl-6">
                        <v-text-field v-model.number="naver_number" class="mt-0" type="number" suffix="명" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>
              <v-col>
                <div class="body-1 font-weight-bold mb-2 mt-4">필수 키워드</div>
                <div class="label mb-2">
                  네이버 블로그에서 어떤 키워드로 노출되기를 원하시나요?<span
                    class="caption ml-1 rouge--text"
                    >*필수</span
                  >
                </div>
                <v-row>
                  <v-chip
                    v-for="(keyword, index) in form.requiredKeywords"
                    :key="index"
                    style="height: 40px"
                    class="mt-2"
                    @click:close="removeRequiredKeyword(index)"
                    close
                    color="rouge"
                    label
                    outlined
                    :disabled="form.naver.available === 0"
                    >{{ keyword }}</v-chip
                  >
                  <v-text-field
                    :disabled="form.naver.available === 0"
                    v-model="requiredKeyword"
                    name="requiredKeyword"
                    type="text"
                    placeholder="예) 다이어트 샐러드"
                    class="mt-2"
                    @keyup.enter="addRequiredKeyword"
                    single-line
                    outlined
                    solo
                    flat
                    hide-details
                  ></v-text-field>
                  <v-btn
                    :disabled="form.naver.available === 0"
                    color="rouge"
                    @click="addRequiredKeyword"
                    class="plus_btn"
                    ><v-icon dark>add</v-icon></v-btn
                  >
                  <v-col class="my-0" cols="12" md="12">
                    <span class="caption rouge--text"
                      >*필수 키워드는 최대 3개까지 설정할 수 있습니다.</span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <div class="body-1 font-weight-bold mb-2">추천 키워드</div>
                <div class="body-1 mb-2">
                  네이버 블로그 후기에 포함되었으면 하는 선택키워드가
                  있으신가요?
                </div>
                <v-row>
                  <v-chip
                    v-for="(keyword, index) in this.form.keywords"
                    :key="index"
                    style="height: 40px"
                    class="mt-2"
                    @click:close="removeKeyword(index)"
                    close
                    color="rouge"
                    label
                    outlined
                    >{{ keyword }}</v-chip
                  >
                  <v-text-field
                    @input="keyword = $event"
                    name="keyword"
                    type="text"
                    :rules="[errors.first('keyword') || !errors.has('keyword')]"
                    placeholder="예) 상수동 맛집"
                    class="mt-2"
                    @keyup.native="$event.key === 'Enter' ? addKeyword() : ''"
                    :value="keyword"
                    :disabled="form.naver.available === 0"
                    single-line
                    outlined
                    solo
                    flat
                    hide-details
                  ></v-text-field>
                  <v-btn
                    color="rouge"
                    @click="addKeyword"
                    class="plus_btn"
                    :disabled="form.naver.available === 0"
                    ><v-icon dark>add</v-icon></v-btn
                  >
                </v-row>
              </v-col>
            </v-col>
            <v-col class="mt-2" cols="12" md="9" v-else>
              <h3 class="font-weight-bold my-6">네이버 블로그 채널관리</h3>
              <v-btn
                color="rouge"
                @click="showAdsStart('naver', 'start')"
                flat
                outlined
                style="width: 140px"
                >채널 추가하기</v-btn
              >
            </v-col>
            <div class="line mt-4"></div>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <h3 class="font-weight-bold mt-6 mb-2">혜택 관련 정보</h3>
        </v-col>
        <v-col class="mt-2" cols="12" md="10">
          <div class="body-1 font-weight-bold mb-2">이용제한 여부</div>
          <div class="body-1 mb-2">
            슈퍼멤버스 회원이 방문할 때, 이용 품목에 제한을 두고 싶으신가요?
          </div>
          <v-btn-toggle v-model="isLimitation" @change="checkDone" mandatory>
            <v-btn
              :value="false"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              flat
              >아니오</v-btn
            >
            <v-btn
              :value="true"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              flat
              >예</v-btn
            >
          </v-btn-toggle>
        </v-col>
        <v-col v-if="isLimitation" cols="12" md="10">
          <div class="body-1 font-weight-bold mt-6 mb-2">이용제한 내용</div>
          <v-text-field
            v-model="channel.limitation"
            name="limitation"
            type="text"
            :rules="[errors.first('limitation') || !errors.has('limitation')]"
            hint="예) 주류는 할인이 제공되지 않습니다."
            persistent-hint
            @input="checkDone"
            single-line
            outlined
            solo
            flat
          ></v-text-field>
        </v-col>
      </v-col>
      <v-row justify="center">
        <v-dialog
          v-model="adsStart.show"
          content-class="white-two"
          persistent
          max-width="500px"
        >
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="adsStart.show = false"
                  >close</v-icon
                ><br />
                <h2 class="mt-2 font-weight-bold headline">
                  {{
                    adsStart.state === "start"
                      ? "광고 시작하기"
                      : "광고 상품 변경하기"
                  }}
                </h2>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div class="body-1 mb-6">
                    <span class="rouge--text">'{{ adsData.name }}'</span>의
                    <span class="rouge--text">{{
                      adsStart.type === "naver" ? "네이버 블로그" : "인스타그램"
                    }}</span>
                    광고를
                    {{
                      adsStart.state === "start" ? "시작합니다" : "변경합니다"
                    }}.
                  </div>
                </v-col>
                <!--                <v-col cols="12" md="10" class="mb-6">-->
                <!--                  <v-row>-->
                <!--                    <v-col cols="12" md="12" class="estimate">-->
                <!--                      <bulbIcon class="light_bulb"/>-->
                <!--                      <div v-if="option.hasOwnProperty('price') && option.price != null">-->
                <!--                        추가되는 광고 비용은<br>-->
                <!--                        월 {{(adsStart.type === 'naver' ? option.price.naver.store/10000 : option.price.insta.store/10000)}}만원 입니다.-->
                <!--                      </div>-->
                <!--                    </v-col>-->
                <!--                  </v-row>-->
                <!--                </v-col>-->
                <!--                <v-col cols="12" v-if="adsStart.state === 'start'">-->
                <!--                  <div class="body-1 font-weight-bold">광고 시작 예정일</div>-->
                <!--                  <v-menu ref="adsStartMenu" :close-on-content-click="false" v-model="adsStart.menu" :nudge-right="40"-->
                <!--                          lazy transition="scale-transition" offset-y min-width="290px">-->
                <!--                    <template v-slot:activator="{ on }">-->
                <!--                      <v-text-field v-on="on" v-model="adsStart.dateFormatted" prepend-icon="event"-->
                <!--                                    @blur="adsStart.startDate = parseDate(adsStart.dateFormatted)" style="max-width: 200px"-->
                <!--                      ></v-text-field>-->
                <!--                    </template>-->
                <!--                    <v-date-picker v-model="adsStart.startDate" locale="ko-kr" :min="$moment.tz('Asia/Seoul').add(1, 'day').format('YYYY-MM-DD')" no-title scrollable @input="adsStart.menu = false">-->
                <!--                    </v-date-picker>-->
                <!--                  </v-menu>-->
                <!--                </v-col>-->
                <v-col cols="12" v-if="adsStart.state === 'update'">
                  <div class="body-1 font-weight-bold">광고 변경 예정일</div>
                  <v-text-field
                    :value="
                      $moment(adsStart.startDate)
                        .tz('Asia/Seoul')
                        .add(30, 'day')
                        .format('YYYY. MM. DD')
                    "
                    prepend-icon="event"
                    readonly
                    style="width: 200px"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn class="rouge white-two--text" flat @click.native="addAds()"
                >확인</v-btn
              >
              <v-btn
                color="primary"
                flat
                outlined
                @click.native="adsStart.show = false"
                >취소</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog
          v-model="adsEnd.show"
          content-class="white-two"
          persistent
          max-width="500px"
        >
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="adsEnd.show = false"
                  >close</v-icon
                ><br />
                <h2 class="mt-2 font-weight-bold headline">
                  {{
                    adsStart.state === "start"
                      ? "광고 시작하기"
                      : "광고 상품 변경하기"
                  }}
                </h2>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div class="body-1 mb-6">
                    <span class="rouge--text"
                      >'{{
                        adsStart.type === "naver"
                          ? "네이버 블로그"
                          : "인스타그램"
                      }}/{{ adsData.name }}'</span
                    >
                    광고가<br />
                    등록되었습니다.
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="rouge white-two--text"
                flat
                @click.native="adsEnd.show = false"
                >완료</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="calculator.show" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="calculator.show = false"
                  >close</v-icon
                ><br />
                <h2 class="mt-2 font-weight-bold headline">
                  등급별 할인율 계산해보기
                </h2>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div class="label mb-6">
                    회원 방문시 예상되는 총 이용금액을 아래에 입력해주세요.
                  </div>
                  <v-row>
                    <v-col md="6">
                      <div
                        class="py-2 font-weight-bold"
                        style="font-size: 13px"
                      >
                        할인 전 이용 금액
                      </div>
                    </v-col>
                    <v-col md="6">
                      <v-text-field
                        v-model.number="calculator.estimate"
                        name="estimate"
                        type="number"
                        suffix="원"
                        class="text-right"
                        :rules="[
                          errors.first('estimate') || !errors.has('estimate'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <div class="line mb-6"></div>
                <v-col cols="12">
                  <v-row>
                    <v-col md="6">
                      <div class="label py-2" style="font-size: 13px">
                        할인 적용 최대금액
                      </div>
                    </v-col>
                    <v-col md="6">
                      <v-text-field
                        v-model.number="calculator.maxDiscount"
                        name="maxDiscount"
                        type="number"
                        suffix="원"
                        class="text-right"
                        :rules="[
                          errors.first('maxDiscount') ||
                            !errors.has('maxDiscount'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <div class="label pt-12" style="font-size: 13px">
                        등급별 할인율
                      </div>
                      <div class="label pt-4 mt-4" style="font-size: 13px">
                        등급별 할인금액
                      </div>
                    </v-col>
                    <v-col md="2" class="mx-4">
                      <h5 class="mb-4">블랙 등급</h5>
                      <v-text-field
                        v-model.number="calculator.black"
                        name="calculatorBlack"
                        type="number"
                        suffix="%"
                        class="text-right"
                        :rules="[
                          errors.first('calculatorBlack') ||
                            !errors.has('calculatorBlack'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                      ></v-text-field>
                      <div class="label rouge--text" style="font-size: 13px">
                        {{ blackCal ? blackCal.toLocaleString() : 0 }}원
                      </div>
                    </v-col>
                    <v-col md="2" class="mx-4">
                      <h5 class="mb-4">레드 등급</h5>
                      <v-text-field
                        v-model.number="calculator.red"
                        name="calculatorRed"
                        type="number"
                        suffix="%"
                        class="text-right"
                        :rules="[
                          errors.first('calculatorRed') ||
                            !errors.has('calculatorRed'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                      ></v-text-field>
                      <div class="label rouge--text" style="font-size: 13px">
                        {{ redCal ? redCal.toLocaleString() : 0 }}원
                      </div>
                    </v-col>
                    <v-col md="2" class="mx-4">
                      <h5 class="mb-4">옐로우 등급</h5>
                      <v-text-field
                        v-model.number="calculator.yellow"
                        name="calculatorYellow"
                        type="number"
                        suffix="%"
                        class="text-right"
                        :rules="[
                          errors.first('calculatorYellow') ||
                            !errors.has('calculatorYellow'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                      ></v-text-field>
                      <div class="label rouge--text" style="font-size: 13px">
                        {{ yellowCal ? yellowCal.toLocaleString() : 0 }}원
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <div class="line my-6"></div>
                <v-row>
                  <v-col md="3">
                    <div
                      class="pt-1 label font-weight-bold"
                      style="font-size: 13px"
                    >
                      등급별 결제금액
                    </div>
                  </v-col>
                  <v-col md="2" class="mx-4">
                    <div class="label" style="font-size: 18px">
                      {{ (calculator.estimate - blackCal).toLocaleString() }}원
                    </div>
                  </v-col>
                  <v-col md="2" class="mx-4">
                    <div class="label" style="font-size: 18px">
                      {{ (calculator.estimate - redCal).toLocaleString() }}원
                    </div>
                  </v-col>
                  <v-col md="2" class="mx-4">
                    <div class="label" style="font-size: 18px">
                      {{ (calculator.estimate - yellowCal).toLocaleString() }}원
                    </div>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <!--<v-spacer></v-spacer>-->
              <v-btn
                class="rouge white-two--text"
                flat
                @click.native="calculator.show = false"
                >닫기</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Page4",
  props: ["adsData", "option"],
  components: {},
  data: function () {
    return {
      MAX_REQUIRED_KEYWORD_COUNT: 3,
      form: {
        keywords: this.adsData.keywords || [],
        hashtags: this.adsData.hashtags || [],
        requiredKeywords: this.adsData.requiredKeywords || [],
        diamondPoint: this.adsData.diamondPoint || 20000,
        blackPoint: this.adsData.blackPoint || 20000,
        redPoint: this.adsData.redPoint || 20000,
        naver: {
          enableWhite: this.adsData?.naver?.enableWhite || false,
        },
      },
      channel: {
        limitation:
          (this.adsData.naver || {}).limitation ||
          (this.adsData.insta || {}).limitation,
      },
      naverPrice: 0,
      instaPrice: 0,
      naverMenu: false,
      instaMenu: false,
      naverDateFormatted: null,
      instaDateFormatted: null,
      // isLimitation: false,
      adsStart: {
        show: false,
        type: "naver",
        startDate: null,
        menu: false,
        dateFormatted: null,
      },
      adsEnd: {
        show: false,
      },
      calculator: {
        show: false,
        estimate: 0,
        maxDiscount: 50000,
        black: 100,
        red: 70,
        yellow: 30,
      },
      naver_stopRequest: (this.adsData.naver || {}).stopRequest || false,
      insta_stopRequest: (this.adsData.insta || {}).stopRequest || false,
      keyword: "",
      requiredKeyword: "",
      hashtag: "",
      mission: "",
      naver_number: (this.adsData.naver || {}).number || 20,
      naver_limit: (this.adsData.naver || {}).number === 0 || false
    };
  },
  computed: {
    blackCal() {
      let discount =
        this.calculator.estimate > this.calculator.maxDiscount
          ? this.calculator.maxDiscount
          : this.calculator.estimate;
      return discount * this.calculator.black * 0.01;
    },
    redCal() {
      let discount =
        this.calculator.estimate > this.calculator.maxDiscount
          ? this.calculator.maxDiscount
          : this.calculator.estimate;
      return discount * this.calculator.red * 0.01;
    },
    yellowCal() {
      let discount =
        this.calculator.estimate > this.calculator.maxDiscount
          ? this.calculator.maxDiscount
          : this.calculator.estimate;
      return discount * this.calculator.yellow * 0.01;
    },
    tickLabels() {
      if (this.adsData.type === 'A') {
        return Array(10)
            .fill()
            .map((v, i) => {
              if ([5, 25, 50].indexOf((i + 1) * 5) >= 0) {
                return (i + 1) * 5;
              } else return '';
            });
      }
      else {
        return Array(10)
            .fill()
            .map((v, i) => {
              if ([10, 50, 100].indexOf((i + 1) * 10) >= 0) {
                return (i + 1) * 10;
              } else return '';
            });
      }
    }
  },
  watch: {
    adsData: {
      handler: function (value) {
        this.form = {
          keywords: value.keywords || [],
          hashtags: value.hashtags || [],
          requiredKeywords: value.requiredKeywords || [],
          diamondPoint: value.diamondPoint || 0,
          blackPoint: value.blackPoint || 0,
          redPoint: value.redPoint || 0
        };
        this.channel = {
          limitation:
            (value.naver || {}).limitation || (value.insta || {}).limitation,
        };
        this.naver_stopRequest = (value.naver || {}).stopRequest || false;
        this.insta_stopRequest = (value.insta || {}).stopRequest || false;

        this.initData();
      },
      // immediate: true,
      deep: true,
    },
    "form.naver.startDate": function () {
      this.naverDateFormatted = this.formatDate(this.form.naver.startDate);
    },
    "form.insta.startDate": function () {
      this.instaDateFormatted = this.formatDate(this.form.insta.startDate);
    },
    "adsStart.startDate": function () {
      this.adsStart.dateFormatted = this.formatDate(this.adsStart.startDate);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}. ${month}. ${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split(". ");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    calculate: function (channel) {
      this.calculator.show = true;
      this.calculator.maxDiscount = this[channel + "Price"];
      this.calculator.black = this.form[channel].black;
      this.calculator.red = this.form[channel].red;
      this.calculator.yellow = this.form[channel].yellow;
    },
    addRequiredKeyword: async function () {
      if (
        this.requiredKeyword.length > 0 &&
        this.form.requiredKeywords.indexOf(this.requiredKeyword) === -1 &&
        this.MAX_REQUIRED_KEYWORD_COUNT > this.form.requiredKeywords.length
      ) {
        this.form.requiredKeywords.push(this.requiredKeyword);
        this.requiredKeyword = "";

        await this.checkDone();
        return null;
      }
    },
    removeRequiredKeyword: function (idx) {
      console.log("remove" + idx);
      this.form.requiredKeywords.splice(idx, 1);
      this.checkDone();
    },
    addKeyword: async function () {
      if (
        this.keyword.length > 0 &&
        this.form.keywords.indexOf(this.keyword) === -1
      ) {
        this.form.keywords.push(this.keyword);
        this.keyword = "";
        await this.checkDone();
      }
      return null;
    },
    removeKeyword: function (idx) {
      console.log("remove" + idx);
      this.form.keywords.splice(idx, 1);
      this.checkDone();
    },
    addHashtag: async function () {
      if (
        this.hashtag.length > 0 &&
        this.form.hashtags.indexOf(this.hashtag) === -1
      ) {
        this.form.hashtags.push(this.hashtag);
        this.hashtag = "";
        await this.checkDone();
      }
      return null;
    },
    removeHashtag: function (idx) {
      console.log("remove" + idx);
      this.form.hashtags.splice(idx, 1);
      this.checkDone();
    },
    addMission: function () {
      this.form.naver.mission = this.form.naver.mission || [];
      if (
          this.mission.length > 0 &&
          this.form.naver.mission.indexOf(this.mission) === -1
      ) {
        this.form.naver.mission.push(this.mission);
        this.mission = "";
        this.checkDone();
      }
    },
    removeMission: function (idx) {
      console.log("remove" + idx);
      this.form.naver.mission.splice(idx, 1);
      this.checkDone();
    },
    async checkDone() {
      let success = await this.$validator.validateAll();
      if (success) {
        // this.form.requiredKeywords =
        //   this.requiredKeyword.length > 0 ? [this.requiredKeyword] : null;
        ["insta", "naver"].forEach((channel) => {
          if (this.form[channel]) {
            this._.merge(
              this.form[channel],
              JSON.parse(JSON.stringify(this.channel))
            );
            if (!this.isLimitation) {
              // delete this.form[channel].limitation;
              this.form[channel].limitation = null;
            }
            this.form[channel].number = this[channel + '_limit'] ? 0 : this[channel + '_number'];
            if (
              typeof this[channel + "Price"] === "string" &&
              this[channel + "Price"].length === 0
            ) {
              this[channel + "Price"] = 0;
            }
            this.form[channel].diamondPrice =
            this.form[channel].blackPrice =
              this.form[channel].redPrice =
              this.form[channel].yellowPrice =
              this.form[channel].whitePrice =
                this[channel + "Price"];
            this.form[channel].stopRequest =
              this[channel + "_stopRequest"] || false;
            ["diamond","black", "red", "yellow", "white"].forEach((level) => {
              if (
                typeof this.form[channel][level] === "string" &&
                this.form[channel][level].length === 0
              ) {
                this.form[channel][level] = 0;
              }
            });
          }
        });

        // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
        this.$emit("updateEvent", this.form);
        this.$store.commit("setDirty", true);
      } else {
        this.$store.commit("setMsg", "입력값 오류로 저장에 실패했습니다.");
        this.$store.commit("setShowSnackbar", true);
      }

      console.log(this.form);
      return null;
    },
    showAdsStart(type, state) {
      if (state == "update") {
        this.adsStart.show = true;
      }
      this.adsStart.type = type;
      this.adsStart.state = state;
      if (type === "naver" && state === "update") {
        this.adsStart.startDate = this.adsData.naver.startDate;
      }
      if (type === "insta" && state === "update") {
        this.adsStart.startDate = this.adsData.insta.startDate;
      }
      if (state == "start") {
        this.addAds();
      }
    },
    addAds() {
      this.adsStart.show = false;
      this.adsEnd.show = true;
      if (this.adsStart.type === "naver") {
        this.form.naver = { black: 100, red: 70, yellow: 30, white: 10 };
        this.form.naver.startDate = this.adsStart.startDate;
        this.naverDateFormatted = this.formatDate(this.form.naver.startDate);
      } else {
        this.form.insta = { black: 100, red: 60, yellow: 30 };
        this.form.insta.startDate = this.adsStart.startDate;
        this.instaDateFormatted = this.formatDate(this.form.insta.startDate);
      }
      this.checkDone();
    },
    initData() {
      ["naver", "insta"].forEach((channel) => {
        if (this.adsData[channel]) {
          this.form[channel] = JSON.parse(
            JSON.stringify(this.adsData[channel])
          ); //_.cloneDeep(this.adsData[channel])
          this[channel + "DateFormatted"] = this.formatDate(
            this.form[channel].startDate
          );
          this[channel + "Price"] = Math.max(
            this.adsData[channel].blackPrice,
            this.adsData[channel].redPrice,
            this.adsData[channel].yellowPrice,
            this.adsData[channel].whitePrice,
            this.adsData[channel].diamondPrice,
          );
          this.isLimitation =
            this.isLimitation || !!this.form[channel].limitation;
        }
      });

      this.adsStart.startDate = this.$moment
        .tz("Asia/Seoul")
        .add(1, "day")
        .format("YYYY-MM-DD");
      this.adsStart.dateFormatted = this.formatDate(this.adsStart.startDate);

      if (!this.adsData.receiptReviewRequired) {
        this.form.naver.enableWhite = false;
      }

      // if (this.adsData["requiredKeywords" || []].length > 0) {
      //   this.requiredKeyword = this.adsData.requiredKeywords[0];
      // }
    },
  },
  created() {
    this.initData();
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/scss/custom";

.checkbox {
  border: 1px solid;
  border-radius: 8px;
  max-width: 130px;
  height: 40px;
  margin-top: 0px;
}

.line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

.label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

::v-deep .light_bulb {
  width: 14px;
  height: 20px;
  object-fit: contain;
  background-color: #00000000;
}

::v-deep input[type="number"]::-webkit-outer-spin-button,
::v-deep input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.plus_btn {
  width: 40px;
  height: 40px !important;
  min-width: 40px !important;
  color: white;
  padding: 0 !important;
}

.level_icon {
  width: 54px;
  height: 64px;
}
</style>
<style lang="scss">
.white-text-field {
  .v-input__slot {
    background-color: #f5f5f5 !important;
  }
}

.v-checkbox-white__container {
  margin-top: 0 !important;
  padding-top: 0 !important;

  margin-bottom: 8px;
  .v-input--selection-controls__input {
    width: 21px !important;
    height: 21px !important;
  }
}
</style>
